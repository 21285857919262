<template>
  <div class="LeaderOfTheGrade">
    <div class="header">
      <div>
        学段：
        <el-select
          v-model="level"
          placeholder="请选择学段"
          @change="selectChange"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        入学年份：
        <el-select
          v-if="level == 2"
          v-model="year"
          placeholder="请选择入学年份"
          @change="selectChange"
        >
          <el-option
            v-for="item in yearMiddleOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-else
          v-model="year"
          placeholder="请选择入学年份"
          @change="selectChange"
        >
          <el-option
            v-for="item in yearHignOptions"
            :key="item.value"
            :label="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button type="primary" plain>批量导入</el-button>
        <el-button type="primary" plain @click="addTeacher">添加</el-button>
        <el-button type="success" plain>导出</el-button>
        <el-button type="danger" plain>清空</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  schoolteacherRolePage,
  // schoolteacherRoleDel,
} from "@/core/api/newOther/authority";
import {
  getGradeList,
  // getClassListByLevelAndYear,
} from "@/core/api/school/schoolgradeclass";
import { levelOptions } from "@/core/util/constdata";
export default {
  name: "LeaderOfTheGrade",
  data() {
    return {
      levelOptions: levelOptions(),
      yearMiddleOptions: [],
      yearHignOptions: [],
      level: "",
      year: "",
      pageIndex: 1,
      pageSize: 10,
      total: 1,
      teacherList: [],
    };
  },
  created() {
    this.level = this.levelOptions[0].value;
    this.getSchoolYearList();
    this.schoolteacherRolePage();
  },
  methods: {
    searchList() {
      this.pageIndex = 1;

      this.schoolteacherRolePage();
    },
    addTeacher() {},
    selectChange() {
      this.searchList();
    },
    // 分页获取老师列表
    schoolteacherRolePage() {
      let data = {
        roleType: 4,
        current: this.pageIndex,
        size: this.pageSize,
        name: this.name,
        roleLevel: this.level,
        roleYear: this.year,
      };
      this.listLoading = true;
      schoolteacherRolePage(data)
        .then((res) => {
          this.teacherList = res.data.data.records;
          this.total = res.data.data.total;
          this.listLoading = false;
          // console.log(res);
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 获取所有年级列表
    getSchoolYearList() {
      getGradeList().then((response) => {
        let grades = response.data.data;
        if (grades && grades.length > 0) {
          grades.forEach((item) => {
            if (item.level == 2) {
              this.yearMiddleOptions.push({
                value: item.year,
                label: item.name,
              });
            } else {
              this.yearHignOptions.push({
                value: item.year,
                label: item.name,
              });
            }
          });
          this.yearHignOptions = [...new Set(this.yearHignOptions)];
          this.yearMiddleOptions = [...new Set(this.yearMiddleOptions)];
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.LeaderOfTheGrade {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .el-input {
      width: 192px;
      i {
        cursor: pointer;
      }
    }
  }
}
</style>
